/** @jsx jsx */
import { jsx, Grid, Text, Heading, Box, Card, Button } from 'theme-ui'
import { useEffect, useState, useMemo, useContext, useCallback } from 'react'
import { Buffer } from 'buffer'
import Layout from '../../components/layout'
import Wrapper from '../../components/layout/wrapper'
import Inner from '../../components/layout/inner'
import { responsive } from '../../utils/helpers'
import {
  Gallery,
  Quantity,
  Variant,
  Customisation,
  OutOfStock,
} from '../../components/shop'
import { Main } from '../../components/article'
// import { ImageCard } from '../../components/card'
import { graphql } from 'gatsby'
import { prepareVariantsWithOptions } from '../../utils/shop'
import StoreContext from '../../contexts/storeContext'
import update from 'immutability-helper'
import MetaData from '../../components/metadata'

// import shirtOne from '../../images/promo-1.png'
// import shirtTwo from '../../images/shirt2.png'

const Product = props => {
  const [customVariants, setCustomVariants] = useState([])
  const [currentVariant, setCurrentVariant] = useState({})
  const [shopifyVariant, setShopifyVariant] = useState()
  const [available, setAvailable] = useState()
  const [price, setPrice] = useState(0)
  const [quantity, setQuantity] = useState(1)
  const [customName, setCustomName] = useState()
  const [playerName, setPlayerName] = useState()
  const [customMessage, setCustomMessage] = useState('')
  const storeContext = useContext(StoreContext)
  const storeHelpers = storeContext.store.client.product.helpers

  const stockSX = {
    fontWeight: 700,
    lineHeight: 'body',
    py: 4,
  }

  const priceSX = {
    fontFamily: 'light',
    fontSize: 8,
    lineHeight: 'body',

    strong: {
      fontFamily: 'heading',
    },
  }

  const shippingSX = {
    fontSize: 0,
  }

  const allVariants = props.data?.allShopifyProductVariant

  // Create searchable list of products that have custom options
  const customisationList = props.data.allContentfulProductCustomisation.edges.map(
    x => Buffer.from(x.node.product, 'base64').toString('ascii')
  )

  // Create searchable list of all customisations that should apply to this product
  const validCustomisations = allVariants?.edges
    .filter(
      x =>
        customisationList.includes(x.node.shopifyId) &&
        x.node.product.handle === props.pageContext?.slug
    )
    .map(x => x.node.shopifyId)
  // Reduce customisations list to be a flat array of variant IDs
  const customisationVariants = props.data.allContentfulProductCustomisation.edges
    .filter(x =>
      validCustomisations.includes(
        Buffer.from(x.node.product, 'base64').toString('ascii')
      )
    )
    .reduce((array, x) => {
      x.node.customisations.forEach(x => {
        array.push(Buffer.from(x, 'base64').toString('ascii'))
      })
      return array
    }, [])

  // Find all custom products for our list of variants
  const customProducts = allVariants?.edges
    ?.filter(x => customisationVariants.includes(x.node.shopifyId))
    .map(x => x.node.product)

  const page = props.data?.shopifyProduct
  const isPreOrder = page.tags.includes('pre-order')

  const variants = useMemo(() => prepareVariantsWithOptions(page.variants), [
    page.variants,
  ])

  const handleVariantChange = (name, value) => {
    if (!name || !value) return
    setCurrentVariant(current => ({ ...current, [name]: value }))
  }

  const handleCustomisationChange = variant => {
    if (!shopifyVariant || !variant) return
    const existingIndex = customVariants.findIndex(
      x => x.product === variant?.product
    )
    if (existingIndex >= 0) {
      if (variant.variant) {
        if (customVariants[existingIndex].variant == variant.variant) return
        // Update
        setCustomVariants(
          update(customVariants, {
            [existingIndex]: {
              $set: variant,
            },
          })
        )
      } else {
        // Remove
        setCustomVariants(
          update(customVariants, {
            $splice: [[existingIndex, 1]],
          })
        )
      }
    } else if (variant.variant) {
      // Add
      setCustomVariants(
        update(customVariants, {
          $push: [variant],
        })
      )
    }
  }

  const getCustomisationPrice = () => {
    return parseFloat(
      customVariants?.reduce(
        (total, x) =>
          total +
          parseFloat(
            x.variant?.presentmentPrices[0].price
              ? x.variant.presentmentPrices[0].price.amount
              : 0
          ),
        0
      )
    )
  }

  useEffect(() => {
    const total =
      parseFloat(shopifyVariant?.presentmentPrices[0].price.amount) +
      getCustomisationPrice()
    setPrice(total.toFixed(2))
  }, [customVariants])

  const handleCustomNameChange = value => {
    if (value.length >= 10) return
    setCustomName(value)
  }

  const checkAvailability = useCallback(
    productId => {
      if (!shopifyVariant) return
      storeContext.store.client.product
        .fetch(productId)
        .then(fetchedProduct => {
          // this checks the currently selected variant for availability
          const result = fetchedProduct.variants.filter(
            variant => variant.id === shopifyVariant.shopifyId
          )
          if (result.length > 0) {
            setAvailable(result[0].available)
          }
        })
    },
    [storeContext.store.client.product, shopifyVariant]
  )

  useEffect(() => {
    checkAvailability(page.shopifyId)
  }, [shopifyVariant, checkAvailability, page.shopifyId])

  useEffect(() => {
    const variant = storeHelpers.variantForOptions(page, currentVariant)
    setShopifyVariant(variant)
    const total =
      parseFloat(variant?.presentmentPrices[0].price.amount) +
      getCustomisationPrice()
    setPrice(total.toFixed(2))
  }, [currentVariant, page, storeHelpers])

  const handleAddToCart = async () => {
    if (storeContext.store.adding) return
    const attributes = getVariantAttributes()

    await storeContext.addVariantToCart(
      shopifyVariant.shopifyId,
      quantity,
      attributes
    )
    await customVariants.reduce(async (promise, x) => {
      // This will wait for the previous loop to resolve first
      await promise
      await storeContext.addVariantToCart(x.variant.shopifyId, quantity)
    }, Promise.resolve())
  }

  const getVariantAttributes = () => {
    const attributes = []
    if (customName) {
      attributes.push({ key: 'Custom name', value: customName })
    }
    return customName ? attributes : false
  }

  return (
    <Layout {...props} noPromo>
      <MetaData title={page?.title} />
      <Wrapper>
        <Inner>
          <Grid columns={responsive(1, 2)} pt={8} mb={8} gap={10}>
            <Gallery
              images={page?.media.map(({ image: x }) => {
                return {
                  thumbnail: x.originalSrc,
                  large: x.originalSrc,
                }
              })}
            />
            <Box as="article">
              <Heading as="h1" sx={{ color: 'black' }}>
                {page.title}
              </Heading>
              <Text sx={stockSX}>
                {available ? 'In stock' : 'Out of stock'}
              </Text>
              <Text sx={priceSX}>
                Your Price: <strong>£{price}</strong>
              </Text>
              <Text sx={shippingSX}>Shipping calculated at checkout</Text>
              <Card variant="slim" mt={8} mb={9} sx={{ overflow: 'visible' }}>
                {variants?.map((x, i) => {
                  return (
                    <Variant
                      key={i}
                      {...x}
                      onChange={e => handleVariantChange(x.name, e)}
                      handleCustomNameChange={handleCustomNameChange}
                      handlePlayerNameChange={e => setPlayerName(e)}
                      handleCustomMessageChange={e => setCustomMessage(e)}
                      customName={customName}
                      playerName={playerName}
                      customMessage={customMessage}
                      selected={currentVariant[x.name]}
                    />
                  )
                })}
                {available ? (
                  <Box>
                    {customProducts?.map(x => (
                      <Customisation
                        {...x}
                        onChange={handleCustomisationChange}
                        onCustomNameChange={value => setCustomName(value)}
                        customname={customName}
                      />
                    ))}
                    <Quantity onChange={e => setQuantity(e)} value={quantity} />
                    <Box p={4}>
                      <Button
                        disabled={storeContext.store.adding}
                        onClick={handleAddToCart}
                      >
                        {storeContext.store.adding ? 'Adding' : 'Add to cart'}
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  <Box p={4}>
                    <OutOfStock
                      productId={page.shopifyId}
                      variantId={shopifyVariant && shopifyVariant.shopifyId}
                      quantity={quantity}
                    />
                  </Box>
                )}
              </Card>
              <Main>
                <Box
                  dangerouslySetInnerHTML={{ __html: page?.descriptionHtml }}
                  sx={{ lineHeight: 'body' }}
                />
              </Main>
            </Box>
          </Grid>
          {/* <Text sx={headingSX}>Frequently bought with</Text>
          <Grid columns={responsive(1, 4)} mb={9}>
            <ImageCard 
              thumbnail={{
                url: shirtTwo
              }}
              title='Lorem ipsum dolor sit'
              date='1599868800'
              aspectRatio={288/398}
              button={{label: 'shop', url: '/shop'}}
              shop
            />
            <ImageCard 
              thumbnail={{
                url: shirtTwo
              }}
              title='Lorem ipsum dolor sit'
              date='1599868800'
              aspectRatio={288/398}
              button={{label: 'shop', url: '/shop'}}
              shop
            />
          </Grid> */}
        </Inner>
      </Wrapper>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ShopifyProduct($slug: String!) {
    shopifyProduct(handle: { eq: $slug }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      options {
        id: shopifyId
        name
        values
      }
      variants {
        id
        title
        presentmentPrices {
          price {
            amount
          }
        }
        availableForSale
        shopifyId
        selectedOptions {
          name
          value
        }
      }
      priceRangeV2 {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      media {
        ... on ShopifyMediaImage {
          image {
            originalSrc
          }
        }
      }
      tags
    }

    allContentfulProductCustomisation {
      edges {
        node {
          product
          customisations
        }
      }
    }

    allShopifyProductVariant(filter: { product: { id: { ne: null } } }) {
      edges {
        node {
          shopifyId
          product {
            id
            title
            handle
            productType
            description
            descriptionHtml
            shopifyId
            options {
              id: shopifyId
              name
              values
            }
            variants {
              id
              title
              presentmentPrices {
                price {
                  amount
                }
              }
              availableForSale
              shopifyId
              selectedOptions {
                name
                value
              }
            }
            priceRangeV2 {
              minVariantPrice {
                amount
                currencyCode
              }
              maxVariantPrice {
                amount
                currencyCode
              }
            }
          }
        }
      }
    }
  }
`

export default Product
